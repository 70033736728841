import {
  CouponDurationTimeLimit,
  IdStringNameStringType,
  PromotionBulkStatus,
  PromotionDiscountType,
  PromotionDurationType,
  PromotionStatus,
  SubscriptionPromotionCountry,
} from "../../model/promotionV2";
import { LocaleType } from "../../../utils/locale";

export interface RecurlyCouponResponse {
  id: string;
  name: string;
  code: string;
  started_at: string;
  ended_at: string | null;
  status: PromotionStatus;
  discount_type: PromotionDiscountType;
  max_redemptions: number;
  percent?: number;
  currencies?: {
    currency_code: string;
    discount_amount: number;
  }[];
}

export type LanguageKeyStringValueType = {
  [key in LocaleType]: string;
};

export interface DescriptionDisclosureDto {
  billing_plan_id?: string;
  billing_plan_name?: string;
  description?: LanguageKeyStringValueType;
  disclosure?: LanguageKeyStringValueType;
}

export enum PromotionType {
  RECURLY = "recurly",
  BULK = "bulk",
  BUY_ONE_GIFT_ONE = "buy_one_gift_one",
}

export interface PromotionResponse {
  id: string;
  name: string;
  code: string;
  started_at: string;
  ended_at?: string;
  status: PromotionStatus;
  type: PromotionType;
  discount_type: PromotionDiscountType;
  base_prices?: IdStringNameStringType[];
  currencies?: PromotionCurrencyDto[];
  percent?: number;
}

export interface PromotionListResponse {
  data: PromotionResponse[];
  count: number;
  offset: number;
  limit: number;
}

export interface PromotionBaseDetailsResponse {
  id: string;
  name: string;
  code: string;
  started_at: string;
  status: PromotionStatus;
  discount_type: PromotionDiscountType;
  can_be_deleted: boolean;
  code_text?: LanguageKeyStringValueType;
  description_disclosures?: DescriptionDisclosureDto[];
}

export interface PromotionWithBuyOneGiftOneDetailsResponse extends PromotionBaseDetailsResponse {
  ended_at: string;
  buy_one_gift_one: {
    buy_plan: PlanDto;
    gift_plan: PlanDto;
  };
}

export interface PromotionWithRecurlyDetailsResponse extends PromotionBaseDetailsResponse {
  recurly_coupon_id: string;
  applied_base_prices: IdStringNameStringType[];
  type: PromotionType;
  applied_countries?: IdStringNameStringType[];
  bulk_quantity?: number;
  ended_at?: string;
  percent?: number;
  currencies?: {
    currency_code: string;
    discount_amount: number;
  }[];
}

export interface PlanDto {
  id: string;
  pid: string;
  title: string;
  billing_plan_id: string;
  billing_plan_name: string;
}

interface SubscriptionPromotionResponse {
  code: string;
  status: PromotionStatus;
  discount_type: PromotionDiscountType;
  applied_countries?: SubscriptionPromotionCountry[];
  percent?: number;
  currencies?: PromotionCurrencyDto[];
  duration?: SubscriptionPromotionDurationDTO;
}

export type SubscriptionPromotionsResponse = SubscriptionPromotionResponse[];

export interface SubscriptionPromotionsAndCountriesResponse {
  countries: SubscriptionPromotionCountry[];
  coupons: SubscriptionPromotionsResponse;
}

interface SubscriptionPromotionDurationDTO {
  type: PromotionDurationType;
  limit_time?: CouponDurationTimeLimit;
}

interface PromotionCurrencyDto {
  currency_code: string;
  discount_amount: number;
}

export interface PromotionBulkResponse {
  id: string;
  code: string;
  status: PromotionBulkStatus;
  applied_at?: string;
  customer_id?: number;
  customer_email?: string;
}

export interface PromotionBulkListResponse {
  data: PromotionBulkResponse[];
  count: number;
  redeemed_count: number;
  offset: number;
  limit: number;
}
