import { ContentBase } from "./description";
import { LocaleType } from "../../utils/locale";

export enum PromotionStatus {
  NOT_STARTED = "not_started",
  REDEEMABLE = "redeemable",
  EXPIRED = "expired",
  MAXED_OUT = "maxed_out",
}

export enum PromotionDiscountType {
  PERCENTAGE_OFF = "percentage_off",
  AMOUNT_OFF = "amount_off",
  BUY_ONE_GIFT_ONE = "buy_one_gift_one",
}

export interface IdStringNameStringType {
  id: string;
  name: string;
}

export enum PromotionCodeUsageType {
  REGULAR = "regular",
  BULK = "single_use",
}

export interface PromotionModel {
  id: string;
  name: string;
  code: string;
  startAt: string;
  status: PromotionStatus;
  discountType: PromotionDiscountType;
  codeUsage?: PromotionCodeUsageType;
  endAt?: string;
  percentageDiscount?: string;
  currencyCode?: string;
  amountDiscount?: string;
  basePrices?: IdStringNameStringType[];
  promotionCodeTexts?: {
    [LocaleType.English]: string;
    [LocaleType.Spanish]: string;
  };
}

export interface PromotionListModel {
  list: PromotionModel[];
  total: number;
}

export interface Currency {
  currency: string;
  amount: number;
}

export interface RecurlyCouponModel {
  id: string;
  name: string;
  code: string;
  startedAt: string;
  endedAt: string | null;
  status: PromotionStatus;
  discountType: PromotionDiscountType;
  maxRedemptions: number;
  percent?: number;
  currencies?: Currency[];
}

export interface DescriptionDisclosure {
  billingPlanId?: string;
  billingPlanName?: string;
  description?: ContentBase[];
  disclosure?: ContentBase[];
}

export interface PromotionWithRecurlyFormData {
  codeUsage: PromotionCodeUsageType;
  code: string;
  displayPromotionCode: string;
  recurlyCouponId: string;
  basePriceIds: string[];
  quantity?: number;
  countryIds?: number[];
  codeTextEn?: string;
  codeTextEs?: string;
  descriptionDisclosures?: DescriptionDisclosure[];
}

export interface PromotionWithBuyOneGiftOneFormData {
  code: string;
  name: string;
  timeRange: [string, string];
  basePlanPid: string;
  giftPlanPid: string;
  codeTextEn?: string;
  codeTextEs?: string;
  description?: ContentBase[];
  disclosure?: ContentBase[];
}

export interface PromotionBaseDetailsModel {
  id: string;
  name: string;
  code: string;
  startedAt: string;
  status: PromotionStatus;
  discountType: PromotionDiscountType;
  canBeDeleted: boolean;
  codeText?: {
    [LocaleType.English]: string;
    [LocaleType.Spanish]: string;
  };
  descriptionDisclosures?: DescriptionDisclosure[];
}

export interface PromotionWithBuyOneGiftOneDetailsModal extends PromotionBaseDetailsModel {
  endedAt: string;
  buyPlan: PlanModal;
  giftPlan: PlanModal;
}

export interface PromotionWithRecurlyDetailsModal extends PromotionBaseDetailsModel {
  recurlyCouponId: string;
  appliedBasePrices: IdStringNameStringType[];
  codeUsage: PromotionCodeUsageType;
  quantity?: number;
  appliedCountries?: IdStringNameStringType[];
  endedAt?: string;
  percent?: number;
  currencies?: Currency[];
}

export interface PlanModal {
  id: string;
  pid: string;
  title: string;
  billingPlanId: string;
  billingPlanName: string;
}

export interface CouponDurationTimeLimit {
  amount: number;
  unit: PromotionDurationLimitTimeUnit;
}

export interface PromotionDuration {
  type: PromotionDurationType;
  limitTime?: CouponDurationTimeLimit;
}

export enum PromotionDurationType {
  FOREVER = "forever",
  SINGLE_USE = "single_use",
  TEMPORAL = "temporal",
}

export enum PromotionDurationLimitTimeUnit {
  YEAR = "year",
  MONTH = "month",
  WEEK = "week",
  DAY = "day",
}

export interface SubscriptionPromotionCountry {
  name: string;
  code: string;
}

export interface SubscriptionPromotion {
  code: string;
  status: PromotionStatus;
  discountType: PromotionDiscountType;
  appliedCountries?: SubscriptionPromotionCountry[];
  percent?: number;
  currencies?: Currency[];
  duration?: PromotionDuration;
}

export interface SubscriptionPromotionsAndCountries {
  countries: SubscriptionPromotionCountry[];
  promotions: SubscriptionPromotion[];
}

export enum PromotionBulkStatus {
  REDEEMED = "Redeemed",
  UNREDEEMED = "Unredeemed",
  EXPIRED = "Expired",
}

export interface PromotionBulkListModel {
  list: PromotionBulkModel[];
  redeemedCount: number;
}

export interface PromotionBulkModel {
  code: string;
  status: PromotionBulkStatus;
  email?: string;
  customerId?: number;
  date?: string;
}
