import isEmpty from "lodash/isEmpty";
import moment from "moment";
import {
  PlanModal,
  PromotionBaseDetailsModel,
  PromotionBulkModel,
  PromotionCodeUsageType,
  PromotionListModel,
  PromotionModel,
  PromotionWithBuyOneGiftOneDetailsModal,
  PromotionWithBuyOneGiftOneFormData,
  PromotionWithRecurlyDetailsModal,
  PromotionWithRecurlyFormData,
  RecurlyCouponModel,
  SubscriptionPromotion,
  SubscriptionPromotionsAndCountries,
} from "../../model/promotionV2";
import {
  PlanDto,
  PromotionBaseDetailsResponse,
  PromotionBulkResponse,
  PromotionListResponse,
  PromotionResponse,
  PromotionType,
  PromotionWithBuyOneGiftOneDetailsResponse,
  PromotionWithRecurlyDetailsResponse,
  RecurlyCouponResponse,
  SubscriptionPromotionsAndCountriesResponse,
  SubscriptionPromotionsResponse,
} from "../../dto/response/promotionV2";
import {
  PromotionWithBuyOneGiftOneCreateRequest,
  PromotionWithRecurlyCreateRequest,
} from "../../dto/request/promotionV2";
import { LocaleType, supportLocalTabs } from "../../../utils/locale";
import { ContentBase } from "../../model/description";
import { DescriptionDisclosure } from "../../model/promotion";
import { parseContent } from "../../../utils/richTextEditor";
import { PROMOTION_BULK_QUANTITY } from "../../../constants/common";

export const transformRecurlyCouponModel = (recurlyCoupons: RecurlyCouponResponse[]): RecurlyCouponModel[] => {
  return recurlyCoupons.map((recurlyCoupon) => ({
    id: recurlyCoupon.id,
    name: recurlyCoupon.name,
    code: recurlyCoupon.code,
    status: recurlyCoupon.status,
    startedAt: recurlyCoupon.started_at,
    endedAt: recurlyCoupon.ended_at,
    discountType: recurlyCoupon.discount_type,
    maxRedemptions: Math.min(recurlyCoupon.max_redemptions, PROMOTION_BULK_QUANTITY),
    percent: recurlyCoupon.percent,
    currencies: recurlyCoupon.currencies?.map(({ currency_code, discount_amount }) => ({
      currency: currency_code,
      amount: discount_amount,
    })),
  }));
};

export function transformPromotionWithRecurlyFormDataRequest(
  formData: PromotionWithRecurlyFormData
): PromotionWithRecurlyCreateRequest {
  return {
    code: formData.code.trim(),
    recurly_coupon_id: formData.recurlyCouponId,
    applied_base_price_ids: formData.basePriceIds,
    bulk_quantity: formData.codeUsage === PromotionCodeUsageType.BULK ? formData.quantity : undefined,
    applied_country_ids: formData.countryIds,
    code_text: transformCodeText(formData.codeTextEn, formData.codeTextEs),
    description_disclosures: transformDescriptionAndDisclosure(formData.descriptionDisclosures),
  };
}

export function transformPromotionWithBuyOneGiftOneFormDataRequest(
  formData: PromotionWithBuyOneGiftOneFormData
): PromotionWithBuyOneGiftOneCreateRequest {
  return {
    code: formData.code,
    name: formData.name,
    started_at: formData.timeRange[0],
    ended_at: formData.timeRange[1],
    buy_one_gift_one: {
      buy_plan_pid: formData.basePlanPid,
      gift_plan_pid: formData.giftPlanPid,
    },
    code_text: transformCodeText(formData.codeTextEn, formData.codeTextEs),
    description_disclosures: transformDescriptionAndDisclosure([
      {
        description: formData.description,
        disclosure: formData.disclosure,
      },
    ]),
  };
}

function transformPromotionBaseDetails(response: PromotionBaseDetailsResponse): PromotionBaseDetailsModel {
  return {
    id: response.id,
    name: response.name,
    code: response.code,
    startedAt: response.started_at,
    status: response.status,
    discountType: response.discount_type,
    canBeDeleted: response.can_be_deleted,
    codeText: response.code_text,
    descriptionDisclosures: response.description_disclosures?.map((descriptionDisclosure) => ({
      billingPlanId: descriptionDisclosure.billing_plan_id,
      billingPlanName: descriptionDisclosure.billing_plan_name,
      description: transformDescriptionDisclosure(descriptionDisclosure.description),
      disclosure: transformDescriptionDisclosure(descriptionDisclosure.disclosure),
    })),
  };
}

export function transformPromotionDetailsWithBuyOneGiftOne(
  response: PromotionWithBuyOneGiftOneDetailsResponse
): PromotionWithBuyOneGiftOneDetailsModal {
  return {
    ...transformPromotionBaseDetails(response),
    endedAt: response.ended_at,
    buyPlan: generatePlan(response.buy_one_gift_one.buy_plan),
    giftPlan: generatePlan(response.buy_one_gift_one.gift_plan),
  };
}

const responseTypeToPromotionCodeUsageTypeMap = {
  [PromotionType.RECURLY]: PromotionCodeUsageType.REGULAR,
  [PromotionType.BULK]: PromotionCodeUsageType.BULK,
  [PromotionType.BUY_ONE_GIFT_ONE]: undefined,
};

export function transformPromotionDetailsWithRecurly(
  response: PromotionWithRecurlyDetailsResponse
): PromotionWithRecurlyDetailsModal {
  return {
    ...transformPromotionBaseDetails(response),
    recurlyCouponId: response.recurly_coupon_id,
    appliedBasePrices: response.applied_base_prices,
    codeUsage: responseTypeToPromotionCodeUsageTypeMap[response.type] as PromotionCodeUsageType,
    quantity: response.bulk_quantity,
    appliedCountries: response.applied_countries,
    endedAt: response.ended_at,
    percent: response.percent,
    currencies: response.currencies?.map(({ currency_code, discount_amount }) => ({
      currency: currency_code,
      amount: discount_amount,
    })),
  };
}

export function transformPromotion(response: PromotionResponse): PromotionModel {
  const usdDiscountAmount = response.currencies?.find(
    (currency) => currency.currency_code.toLocaleLowerCase() === "usd"
  );

  return {
    id: response.id,
    name: response.name,
    code: response.code,
    startAt: response.started_at,
    endAt: response.ended_at,
    discountType: response.discount_type,
    codeUsage: responseTypeToPromotionCodeUsageTypeMap[response.type],
    percentageDiscount: response.percent?.toFixed(2),
    currencyCode: usdDiscountAmount?.currency_code?.toUpperCase(),
    amountDiscount: usdDiscountAmount?.discount_amount?.toFixed(2),
    status: response.status,
    basePrices: response.base_prices,
  };
}

export function transformPromotionList(response: PromotionListResponse): PromotionListModel {
  return {
    list: response.data.map(transformPromotion),
    total: response.count,
  };
}

const transformCodeText = (codeTextEn?: string, codeTextEs?: string) => ({
  [LocaleType.English]: codeTextEn,
  [LocaleType.Spanish]: codeTextEs,
});

const transformDescriptionAndDisclosure = (descriptionDisclosures?: DescriptionDisclosure[]) =>
  descriptionDisclosures?.map((descriptionDisclosure) => ({
    billing_plan_id: descriptionDisclosure.billingPlanId,
    billing_plan_name: descriptionDisclosure.billingPlanName,
    description: generateDescriptionOrDisclosure(descriptionDisclosure.description),
    disclosure: generateDescriptionOrDisclosure(descriptionDisclosure.disclosure),
  }));

const generateDescriptionOrDisclosure = (data?: ContentBase[]) => {
  if (!data || isEmpty(data)) {
    return undefined;
  }

  return data.reduce((acc, cur) => {
    acc[cur.locale] = JSON.stringify(cur.content);
    return acc;
  }, {} as { [key in LocaleType]: string });
};

function transformDescriptionDisclosure(content?: { [key in LocaleType]: string }) {
  return content
    ? supportLocalTabs.map((tab) => ({
        label: tab.label,
        key: tab.key,
        locale: tab.locale,
        content: parseContent(content?.[tab.locale] ?? ""),
        required: false,
      }))
    : undefined;
}

function generatePlan(plan: PlanDto): PlanModal {
  return {
    id: plan.id,
    pid: plan.pid,
    title: plan.title,
    billingPlanId: plan.billing_plan_id,
    billingPlanName: plan.billing_plan_name,
  };
}

export const transformSubscriptionPromotions = (promotions: SubscriptionPromotionsResponse): SubscriptionPromotion[] =>
  promotions.map((coupon) => ({
    code: coupon.code,
    status: coupon.status,
    discountType: coupon.discount_type,
    appliedCountries: coupon.applied_countries || [],
    percent: coupon.percent,
    currencies: coupon.currencies?.map(({ currency_code, discount_amount }) => ({
      currency: currency_code,
      amount: discount_amount,
    })),
    duration: coupon.duration && {
      type: coupon.duration.type,
      limitTime: coupon.duration.limit_time,
    },
  }));

export const transformSubscriptionPromotionsAndCountries = (
  subscriptionPromotionsAndCountriesResponse: SubscriptionPromotionsAndCountriesResponse
): SubscriptionPromotionsAndCountries => {
  return {
    countries: subscriptionPromotionsAndCountriesResponse.countries,
    promotions: transformSubscriptionPromotions(subscriptionPromotionsAndCountriesResponse.coupons),
  };
};

export function transformPromotionBulkModal(response: PromotionBulkResponse): PromotionBulkModel {
  return {
    code: response.code,
    status: response.status,
    email: response.customer_email,
    customerId: response.customer_id,
    date: response.applied_at ? moment(response.applied_at).local().format("YYYY-MM-DD HH:mm:ss") : undefined,
  };
}
