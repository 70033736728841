import upperFirst from "lodash/upperFirst";
import { supportLocalTabs } from "../../../utils/locale";
import { ContentBase } from "../../../types/model/description";
import {
  Currency,
  PromotionCodeUsageType,
  PromotionDiscountType,
  PromotionDurationType,
  PromotionStatus,
  SubscriptionPromotion,
} from "../../../types/model/promotionV2";
import { EMPTY } from "../../../constants/common";

export const getDisplayPromotionDiscountType = (promotionDiscountType: PromotionDiscountType) => {
  switch (promotionDiscountType) {
    case PromotionDiscountType.PERCENTAGE_OFF:
      return "Percentage off";
    case PromotionDiscountType.AMOUNT_OFF:
      return "Amount off";
    case PromotionDiscountType.BUY_ONE_GIFT_ONE:
      return "Buy one gift one";
    default:
      return;
  }
};

export const getDisplayPromotionStatus = (promotionStatus?: PromotionStatus) => {
  switch (promotionStatus) {
    case PromotionStatus.NOT_STARTED:
      return "Not started";
    case PromotionStatus.REDEEMABLE:
      return "Redeemable";
    case PromotionStatus.EXPIRED:
      return "Expired";
    case PromotionStatus.MAXED_OUT:
      return "Maxed out";
    default:
      return;
  }
};

export const getDisplayPromotionCodeUsage = (promotionCodeUsage?: PromotionCodeUsageType) => {
  switch (promotionCodeUsage) {
    case PromotionCodeUsageType.REGULAR:
      return "Regular";
    case PromotionCodeUsageType.BULK:
      return "Single Use";
    default:
      return EMPTY;
  }
};

export const getBulkPromotionDisplayCode = (code: string, codeUsage?: PromotionCodeUsageType) =>
  codeUsage === PromotionCodeUsageType.BULK ? `${code}******` : code;

export const getPromotionCodeLabel = (codeUsage?: PromotionCodeUsageType) =>
  codeUsage === PromotionCodeUsageType.BULK ? "Promotion code prefix" : "Promotion code";

export const getDisplayDiscount = (
  discountType: PromotionDiscountType,
  percent?: number,
  currencies?: Currency[]
): string => {
  return discountType === PromotionDiscountType.PERCENTAGE_OFF
    ? `${percent!.toFixed(2)}%`
    : currencies!.map(({ currency, amount }) => `${currency} ${amount.toFixed(2)}`).join(" / ");
};

export const getDiscount = (selectedPromotion?: SubscriptionPromotion) => {
  if (!selectedPromotion) {
    return EMPTY;
  }

  return getDisplayDiscount(selectedPromotion.discountType, selectedPromotion.percent, selectedPromotion.currencies);
};

export const getDisplayDuration = (selectedPromotion?: SubscriptionPromotion) => {
  switch (selectedPromotion?.duration?.type) {
    case PromotionDurationType.SINGLE_USE:
      return "Single use";
    case PromotionDurationType.FOREVER:
      return "Forever";
    case PromotionDurationType.TEMPORAL:
      const durationAmount = selectedPromotion?.duration.limitTime?.amount;
      const durationUnit = selectedPromotion?.duration.limitTime?.unit;
      return durationAmount === 1
        ? `${durationAmount} ${upperFirst(durationUnit)}`
        : `${durationAmount} ${upperFirst(durationUnit)}s`;
    default:
      return EMPTY;
  }
};

export const transformDescriptionDisclosure = (values: any, contentKey: string) => {
  return Object.keys(values)
    .filter((key) => key.includes(contentKey))
    .map((key) => {
      const locale = key.split(contentKey)[0];
      return {
        ...supportLocalTabs.find((item) => item.key === locale),
        required: false,
        content: values[key],
      } as ContentBase;
    });
};
