import { ConfigProvider, Form, message, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { ISubscriptionModalProps } from "./types";
import { EComModal } from "../../../../components/EComModal";
import { EMPTY, validateMessages } from "../../../../constants/common";
import modalStyles from "../../../../components/EComModal/index.module.scss";
import { DropDown } from "../../../../assets/icons";
import { fetchSubscriptionPromotionsV2, submitApplyPromoCodeV2 } from "../../../../services/subscription";
import ModalListItem from "../../../../components/EComModal/ModalListItem";
import ModalContent from "../../../../components/EComModal/ModalContent";
import { SubscriptionPromotion, SubscriptionPromotionCountry } from "../../../../types/model/promotionV2";
import { getDisplayDiscount, getDisplayDuration } from "../../../marketing/RecurlyPromotion/util";

export default function ApplyPromoCodeModalV2({
  auth0Id,
  subscription,
  visible,
  closeModal,
  onFinish,
}: ISubscriptionModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [countries, setCountries] = useState<SubscriptionPromotionCountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<SubscriptionPromotionCountry>();
  const [promotions, setPromotions] = useState<SubscriptionPromotion[]>([]);
  const [selectedPromotion, setSelectedPromotion] = useState<SubscriptionPromotion>();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchPromotions().then();
  }, []);

  useEffect(() => {
    form.resetFields(["promoCode"]);
    setSelectedPromotion(undefined);
  }, [selectedCountry]);

  async function fetchPromotions() {
    try {
      setIsFetching(true);
      const { promotions, countries } = await fetchSubscriptionPromotionsV2(subscription.subscriptionId);
      setPromotions(promotions);
      setCountries(countries);
    } finally {
      setIsFetching(false);
    }
  }

  async function onSubmit() {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      await submitApplyPromoCodeV2(subscription.subscriptionId, values.promoCode, values.country, auth0Id);
      message.success("Applied successfully");
      if (onFinish) {
        onFinish();
      }
      closeModal();
    } finally {
      setIsLoading(false);
    }
  }

  const availablePromotions = () => {
    return promotions.filter((item) => {
      return item.appliedCountries?.length === 0
        ? true
        : item.appliedCountries?.some((country) => country.code === selectedCountry?.code);
    });
  };

  const discount = () => {
    if (!selectedPromotion) {
      return EMPTY;
    }

    return getDisplayDiscount(selectedPromotion.discountType, selectedPromotion.percent, selectedPromotion.currencies);
  };

  return (
    <EComModal
      title="Apply promo code"
      visible={visible}
      okText="Apply promo code"
      cancelText="Cancel"
      onCancel={closeModal}
      onOk={onSubmit}
      confirmLoading={isLoading}
    >
      <Spin size="large" spinning={isFetching}>
        <ConfigProvider form={{ validateMessages }}>
          <Form className={modalStyles.form} form={form}>
            <Form.Item
              label="Country"
              name="country"
              required
              className={modalStyles.formItem}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                optionFilterProp="label"
                suffixIcon={<DropDown />}
                options={countries.map((item) => ({
                  value: item.code,
                  label: item.name,
                }))}
                onChange={(value) => {
                  const country = countries.find((item) => item.code === value);
                  setSelectedCountry(country);
                }}
              />
            </Form.Item>
            {selectedCountry && (
              <Form.Item
                label="Apply promo code"
                name="promoCode"
                dependencies={["country"]}
                required
                rules={[{ required: true }]}
                className={modalStyles.formItem}
              >
                <Select
                  allowClear
                  suffixIcon={<DropDown />}
                  options={availablePromotions().map((item) => ({
                    value: item.code,
                    label: item.code,
                  }))}
                  onChange={(value) => {
                    const promotion = promotions.find((item) => item.code === value);
                    setSelectedPromotion(promotion);
                  }}
                />
              </Form.Item>
            )}
          </Form>
          {selectedPromotion && (
            <ModalContent style={{ marginTop: "0" }}>
              <ModalListItem label="Discount:" value={discount()} />
              <ModalListItem label="Duration:" value={getDisplayDuration(selectedPromotion)} />
            </ModalContent>
          )}
        </ConfigProvider>
      </Spin>
    </EComModal>
  );
}
